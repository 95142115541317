import { orange } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    status: {
      danger: string;
    };
    fontSets: Record<string, Record<string, string>>;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
    fontSets?: Record<string, Record<string, string>>;
  }
}

export const theme = createMuiTheme({
  typography: {
    fontSize: 16,
    htmlFontSize: 10,
  },
  status: {
    danger: orange[500],
  },
  palette: {
    type: "dark",
    primary: {
      500: "#00CC66",
      600: "#003333",
      700: "rgba(0, 204, 102, 0.4)",
    },
    secondary: {
      main: "#336666",
    },
    grey: {
      50: "#000000",
      100: "rgba(255,255,255,0.1)",
      200: "rgba(255, 255, 255, 0.12)",
      400: "rgba(255,255,255,0.4)",
      500: "#FFFFFF",
      600: "rgba(255,255,255,0.6)",
      900: "rgba(255, 255, 255, 0.9)",
    },
    error: {
      500: "#FF3333",
      700: "rgba(255, 204, 0, 0.9)",
      800: "rgba(255, 102, 0, 0.9)",
      900: "rgba(255, 51, 51, 0.9)",
    },
    warning: {
      400: "#FFCC00",
      500: "#FF6600",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        label: {
          color: "#003333",
          fontWeight: 900,
          fontSize: "1.4rem",
          fontFamily: "Roboto",
          letterSpacing: "0.125rem",
        },
      },
      outlinedPrimary: {
        border: "1.5px solid",
        borderColor: "#00CC66",
      },
    },
    MuiFormLabel: {
      filled: {
        fontSize: "1.4rem",
        fontFamily: "Roboto",
        letterSpacing: "0.04rem",
      },
      root: {
        fontSize: "1.6rem",
        fontFamily: "Roboto",
        letterSpacing: "0.05rem",
        color: "rgba(255, 255, 255, 0.6)",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: "1.6rem",
        fontFamily: "Roboto",
        letterSpacing: "0.05rem",
        color: "rgba(255, 255, 255, 0.9)",
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: "1rem",
      },
      root: {
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          border: "1.49px solid rgba(255, 255, 255, 0.5)",
        },
      },
    },
    MuiFilledInput: {
      underline: {
        "&::before": {
          borderBottom: "1px solid rgba(255, 255, 255, 0.4)",
        },
      },
    },
    MuiIconButton: {
      root: {
        color: "rgba(255, 255, 255, 0.4)",
      },
    },
    MuiFormControlLabel: {
      label: {
        color: "rgba(255, 255, 255, 0.9)",
        opacity: "0.9",
      },
    },
    MuiTypography: {
      body1: {
        fontFamily: "Roboto",
        fontSize: "1.8rem",
        letterSpacing: "0.025rem",
        fontWeight: 500,
      },
    },
  },
  fontSets: {
    heading1: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "2.6rem",
      letterSpacing: "0.02rem",
    },
    heading2: {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: "2.2rem",
      letterSpacing: "0.04rem",
    },
    heading3: {
      fontFamily: "Roboto",
      fontWeight: "500",
      fontSize: "1.8rem",
      letterSpacing: "0.04rem",
    },
    heading4: {
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontSize: "1.6rem",
      letterSpacing: "0.02rem",
    },
    heading5: {
      fontFamily: "Roboto",
      fontWeight: "500",
      fontSize: "1.4rem",
      letterSpacing: "0.02rem",
    },
    heading6: {
      fontFamily: "Roboto",
      fontSize: "1.4rem",
      letterSpacing: "0.025rem",
    },
    heading7: {
      fontFamily: "Roboto",
      fontSize: "1.2rem",
      letterSpacing: "0.05rem",
    },
    body1: {
      fontFamily: "Roboto",
      fontSize: "1.6rem",
      letterSpacing: "0.05rem",
      fontWeight: "normal",
    },
    body2: {
      fontFamily: "Roboto",
      fontSize: "1.4rem",
      letterSpacing: "0.03rem",
      fontWeight: "normal",
    },
    caption: {
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontSize: "1.3rem",
      letterSpacing: "0.05rem",
    },
    overline: {
      fontFamily: "Roboto Condensed",
      fontWeight: "bold",
      fontSize: "1rem",
      letterSpacing: "0.135rem",
    },
  },
});
