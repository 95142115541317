import React, { FC } from "react";
import { Box, Grid } from "atoms";

export const AppWrapper: FC = ({ children }) => {
  return (
    <Box bgcolor="#e6ffe6" minHeight="100vh" height="100%">
      <Grid
        bgcolor="#ccffcc"
        height="100%"
        maxWidth={{ sm: "74rem", lg: "92rem" }}
        px={{ xs: "0rem", md: "1.1rem" }}
        mx="auto"
      >
        <Box bgcolor="primary.600" minHeight="100vh" height="100%">
          {children || null}
        </Box>
      </Grid>
    </Box>
  );
};
