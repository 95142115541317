import { useCallback, useReducer } from "react";
import { api } from "utils/api/api";
import { toast } from "react-toastify";
import {
  createContainer,
  createReducer,
  createAsyncActions,
} from "utils/context";
import { EnrtyMetadata, TimeEntryDetail } from "typings/entry";
import { ClientData } from "typings/client";
import { CaseData } from "typings/case";
import { ClaimData } from "typings/claim";

export type AddEntryIntakeContextType = {
  clients?: ClientData[];
  cases?: CaseData[];
  claims?: ClaimData[];
  entryMetadata?: EnrtyMetadata;
  caseLoading: boolean;
  clientLoading: boolean;
  claimLoading: boolean;
  entryId?: number;
  entryDetail?: TimeEntryDetail;
  entryDetailLoading: boolean;
  entryMetadataLoading?: boolean;
  addressId?: number;
};

const initialState: AddEntryIntakeContextType = {
  caseLoading: true,
  clientLoading: true,
  claimLoading: true,
  entryDetailLoading: true,
  entryMetadataLoading: true,
};

const actions = {
  setClients: createAsyncActions("SET_CLIENTS"),
  setCases: createAsyncActions("SET_CASES"),
  setClaims: createAsyncActions("SET_CLAIMS"),
  getEntryMetadta: createAsyncActions("GET_ENTRY_METADATA"),
  setNewEntry: createAsyncActions("SET_NEW_ENTRY"),
  setEntryDetail: createAsyncActions("SET_ENTRY_DETAIL"),
  setUpdatedEntryDetails: createAsyncActions("SET_UPDATED_ENTRY_DETAIL"),
  setNewAddress: createAsyncActions("SET_NEW_ADDRESS"),
};

const claimsReducer = createReducer<AddEntryIntakeContextType>({
  [`${actions.setClients.loading}`]: (state) => ({
    ...state,
    clientLoading: true,
    clients: [],
  }),
  [`${actions.setClients.success}`]: (state, { payload }) => ({
    ...state,
    clients: payload.data,
    clientLoading: false,
  }),
  [`${actions.setClients.failure}`]: (state) => ({
    ...state,
    clientLoading: false,
  }),
  [`${actions.setCases.loading}`]: (state) => ({
    ...state,
    caseLoading: true,
    cases: [],
  }),
  [`${actions.setCases.success}`]: (state, { payload }) => ({
    ...state,
    cases: payload.data,
    caseLoading: false,
  }),
  [`${actions.setCases.failure}`]: (state) => ({
    ...state,
    caseLoading: false,
  }),
  [`${actions.setClaims.loading}`]: (state) => ({
    ...state,
    claimLoading: true,
    claims: [],
  }),
  [`${actions.setClaims.success}`]: (state, { payload }) => ({
    ...state,
    claims: payload.data,
    claimLoading: false,
  }),
  [`${actions.setClaims.failure}`]: (state) => ({
    ...state,
    claimLoading: false,
  }),
  [`${actions.getEntryMetadta.loading}`]: (state) => ({
    ...state,
    entryMetadataLoading: true,
  }),
  [`${actions.getEntryMetadta.success}`]: (state, { payload }) => ({
    ...state,
    entryMetadata: payload.data,
    entryMetadataLoading: false,
  }),
  [`${actions.getEntryMetadta.failure}`]: (state) => ({
    ...state,
    entryMetadataLoading: false,
  }),
  [`${actions.setNewEntry.success}`]: (state, { payload }) => ({
    ...state,
    entryId: payload,
  }),
  [`${actions.setEntryDetail.loading}`]: (state) => ({
    ...state,
    entryDetailLoading: true,
  }),
  [`${actions.setEntryDetail.success}`]: (state, { payload }) => ({
    ...state,
    entryDetail: payload.data,
    entryDetailLoading: false,
  }),
  [`${actions.setEntryDetail.failure}`]: (state) => ({
    ...state,
    entryDetailLoading: false,
  }),
  [`${actions.setNewAddress.success}`]: (state, { payload }) => ({
    ...state,
    addressId: payload,
  }),
});

export const {
  useContext: useAddEntryIntake,
  Provider: AddEntryIntakeProvider,
} = createContainer(() => {
  const [state, dispatch] = useReducer(claimsReducer, initialState);

  const getClientsSearchList = useCallback(async () => {
    dispatch(actions.setClients.loading());
    try {
      const { data } = await api(`/attorney-clients/search`, {
        method: "get",
        params: {
          mode: "all",
        },
      });
      dispatch(actions.setClients.success(data));
    } catch (e) {
      toast.error(e.message);
      dispatch(actions.setClients.failure());
    }
  }, []);

  const getCasesSearchList = useCallback(async (clientId?: number) => {
    dispatch(actions.setCases.loading());
    try {
      const { data } = await api(`/attorney-cases/search`, {
        method: "get",
        params: {
          mode: "all",
          ...(clientId && { attorney_client_id: clientId }),
        },
      });
      dispatch(actions.setCases.success(data));
    } catch (e) {
      toast.error(e.message);
      dispatch(actions.setCases.failure());
    }
  }, []);

  const getClaimsSearchList = useCallback(async (caseId?: number) => {
    dispatch(actions.setClaims.loading());
    try {
      const { data } = await api(`/attorney-claims/search`, {
        method: "get",
        params: {
          mode: "all",
          ...(caseId && { attorney_case_id: caseId }),
        },
      });
      dispatch(actions.setClaims.success(data));
    } catch (e) {
      toast.error(e.message);
      dispatch(actions.setClaims.failure());
    }
  }, []);

  const getEntryMetadataList = useCallback(async (formData) => {
    try {
      const { data } = await api(`entries/add-metadata`, {
        method: "get",
        params: {
          ...formData,
        },
      });
      dispatch(actions.getEntryMetadta.success(data));
    } catch (e) {
      toast.error(e.message);
      dispatch(actions.getEntryMetadta.failure());
    }
  }, []);

  const setNewEntry = useCallback(async (formData, onSuccess: () => void) => {
    dispatch(actions.setNewEntry.loading());
    try {
      const { data } = await api(`/entries`, {
        method: "post",
        data: formData,
      });
      dispatch(actions.setNewEntry.success(data?.data?.entry_id));
      toast.success(data.message);
      onSuccess();
    } catch (e) {
      toast.error(e.message);
      dispatch(actions.setNewEntry.failure());
    }
  }, []);

  const getEntryDetail = useCallback(async (id) => {
    try {
      const { data } = await api(`/entries/${id}`, {
        method: "get",
      });
      dispatch(actions.setEntryDetail.success(data));
    } catch (e) {
      toast.error(e.message);
      dispatch(actions.setEntryDetail.failure());
    }
  }, []);

  const setUpdatedEntryDetails = useCallback(
    async (id: number, formData, onSuccess: () => void) => {
      dispatch(actions.setUpdatedEntryDetails.loading());
      try {
        const { data } = await api(`/entries/${id}`, {
          method: "POST",
          data: formData,
        });
        dispatch(actions.setUpdatedEntryDetails.success(data));
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
        dispatch(actions.setUpdatedEntryDetails.failure());
      }
      return undefined;
    },
    []
  );

  const addNewAddress = useCallback(
    async (formData, onSuccess: () => void) => {
      try {
        const { data } = await api(`/standard-addresses`, {
          method: "POST",
          data: { ...formData },
        });
        getEntryMetadataList({
          entry_type_id: 3,
        });
        dispatch(actions.setNewAddress.success(data?.data?.standard_origin_id));
        onSuccess();
      } catch (e) {
        toast.error(e.message);
      }
      return undefined;
    },
    [getEntryMetadataList]
  );

  const resetAddress = useCallback(async () => {
    dispatch(actions.setNewAddress.success(""));
  }, []);

  const deleteEntryFile = useCallback(async (id, onSuccess: () => void) => {
    try {
      const { data } = await api(`/entry-documents/${id}`, {
        method: "delete",
      });
      toast.success(data.message);
      onSuccess();
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  return {
    state,
    actions: {
      getClientsSearchList,
      getCasesSearchList,
      getClaimsSearchList,
      getEntryMetadataList,
      setNewEntry,
      getEntryDetail,
      setUpdatedEntryDetails,
      addNewAddress,
      deleteEntryFile,
      resetAddress,
    },
  };
});
