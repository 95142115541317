import { useCallback, useReducer } from "react";
import { toast } from "react-toastify";
import { AcapMetadata, UserData } from "typings/user";
import { api } from "utils/api/api";
import {
  createContainer,
  createReducer,
  createAsyncActions,
} from "utils/context";

export type MyAccountContextType = {
  userDetail?: UserData;
  loading: boolean;
  userAcapDetail?: any;
  acapDetailLoading: boolean;
  acapMetadata?: AcapMetadata[];
  acapMetaDataLoading: boolean;
};

const initialState: MyAccountContextType = {
  loading: true,
  acapDetailLoading: true,
  acapMetaDataLoading: true,
};

const actions = {
  setUserDetail: createAsyncActions("SET_USER_DETAIL"),
  setUserProfile: createAsyncActions("SET_USER_PROFILE"),
  setUserAcapDetails: createAsyncActions("SET_USER_ACAP_DETAILS"),
  setAcapMetaData: createAsyncActions("SET_CASE_META_DATA"),
  setUserUpdatedAcapDetails: createAsyncActions("SET_USER_PROFILE"),
};

const claimsReducer = createReducer<MyAccountContextType>({
  [`${actions.setUserDetail.loading}`]: (state) => ({
    ...state,
    loading: true,
  }),
  [`${actions.setUserDetail.success}`]: (state, { payload }) => ({
    ...state,
    userDetail: payload.data,
    loading: false,
  }),
  [`${actions.setUserDetail.failure}`]: (state) => ({
    ...state,
    loading: false,
  }),
  [`${actions.setUserAcapDetails.loading}`]: (state) => ({
    ...state,
    acapDetailLoading: true,
  }),
  [`${actions.setUserAcapDetails.success}`]: (state, { payload }) => ({
    ...state,
    userAcapDetail: payload.data,
    acapDetailLoading: false,
  }),
  [`${actions.setUserAcapDetails.failure}`]: (state) => ({
    ...state,
    acapDetailLoading: false,
  }),
  [`${actions.setAcapMetaData.loading}`]: (state) => ({
    ...state,
    acapMetaDataLoading: true,
  }),
  [`${actions.setAcapMetaData.success}`]: (state, { payload }) => ({
    ...state,
    acapMetadata: payload,
    acapMetaDataLoading: false,
  }),
  [`${actions.setAcapMetaData.failure}`]: (state) => ({
    ...state,
    acapMetaDataLoading: false,
  }),
});

export const {
  useContext: useAccount,
  Provider: AccountProvider,
} = createContainer(() => {
  const [state, dispatch] = useReducer(claimsReducer, initialState);

  const getUserList = useCallback(async () => {
    try {
      const { data } = await api("/clients/profile", {
        method: "get",
      });
      dispatch(actions.setUserDetail.success(data));
    } catch (e) {
      toast.error(e.message);
      dispatch(actions.setUserDetail.failure());
    }
  }, []);

  const setUserProfile = useCallback(async (formData) => {
    dispatch(actions.setUserProfile.loading());
    try {
      const { data } = await api(`/clients/update-profile`, {
        method: "post",
        data: { _method: "PATCH", ...formData },
      });
      dispatch(actions.setUserProfile.success(data));
      toast.success(data.message);
      return data;
    } catch (e) {
      toast.error(e.message);
      dispatch(actions.setUserProfile.failure());
    }
    return undefined;
  }, []);

  const setUserAcapDetails = useCallback(
    async (
      values: { password: string },
      onSuccess: () => void,
      onError?: (error: { message: string }) => void
    ) => {
      try {
        const { data } = await api("/clients/get-acap-details", {
          method: "post",
          data: values,
        });
        onSuccess();
        dispatch(actions.setUserAcapDetails.success(data));
      } catch (e) {
        onError?.(e);
      }
    },
    []
  );

  const getAcapMetadata = useCallback(async () => {
    dispatch(actions.setAcapMetaData.loading());
    try {
      const { data } = await api(`/clients/update-acap-details-metadata`, {
        method: "get",
      });
      dispatch(
        actions.setAcapMetaData.success(
          data.data.reference_acap_security_questions
        )
      );
    } catch (e) {
      dispatch(actions.setAcapMetaData.failure());
    }
  }, []);

  const setUserUpdatedAcapDetails = useCallback(
    async (
      formData,
      onSuccess: () => void,
      onError: (error: { message: string }) => void
    ) => {
      dispatch(actions.setUserUpdatedAcapDetails.loading());
      try {
        const { data } = await api(`/clients/update-acap-details`, {
          method: "post",
          data: { _method: "PATCH", ...formData },
        });
        dispatch(actions.setUserUpdatedAcapDetails.success(data));
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        onError(e);
        if (e.message !== "Incorrect Profile password") {
          toast.error(e.message);
        }

        dispatch(actions.setUserUpdatedAcapDetails.failure());
      }
      return undefined;
    },
    []
  );

  return {
    state,
    actions: {
      getUserList,
      setUserProfile,
      setUserAcapDetails,
      getAcapMetadata,
      setUserUpdatedAcapDetails,
    },
  };
});
