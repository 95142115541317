export const en = {
  appname: "Indigent Billing Solutions",
  logout: "SIGN OUT",
  signout: "Sign Out",
  yes: "yes",
  no: "no",
  email: "Email",
  phone: "Phone",
  password: "password",
  edit: "Edit",
  view: "View",
  viewall: "View All",
  retry: "Retry",
  cancel: "Cancel",
  submit: "Submit",
  next: "Next",
  save: "Save",
  apply: "Apply",
  proceed: "Proceed",
  search: "Search",
  filter: "Filter",
  reset: "reset",
  "error.somethingwentwrong": "Something went wrong",
  "form.error.required": "Required",
  "button.add": "Add",
  "button.remove": "Remove",
  "button.seemore": "Show More",
  "button.back.home": "Back to Home",

  // Login Form
  "login.form.label.email": "Enter Email",
  "login.form.label.password": "Enter Password",
  "login.form.heading1": "Indigent",
  "login.form.heading2": "Billing Solutions, Inc",
  "login.form.heading3": "Sign In",
  "login.form.button.text": "Submit",
  "login.form.forgot.password": "Forgot Password?",
  "login.form.invalid.text": "This doesn’t match an account",
  "login.form.remember.text": "Remember me",

  // Change Password
  "change.password.heading": "Change Password",
  "change.password.form.label.old": "Current Password",
  "change.password.form.label.new": "Enter Password",
  "change.password.form.label.confirm": "Re-enter Password",
  "confirmpassword.mustmatch": "Passwords must match",
  "newpassword.mincharacters": "Should be minimum 8 characters long",
  "newpassword.maxcharacters": "Should be maximum 12 characters long",
  "newpassword.pattern": "Password is not valid",

  // Forgot Password
  "forgot.password.heading": "Enter Email Address",
  "forgot.password.text": "Enter the email assigned to your account.",
  "forgot.password.heading.success": "Check your Email",
  "forgot.password.text.success":
    "An email with further instructions have been sent to:",
  "forgot.password.success.message":
    "Please click on the password reset link that has been sent to your email account.",

  // Reset Password
  "reset.password.heading": "Create New Password",
  "reset.password.rule1": "At least 8 characters",
  "reset.password.rule2": "A mixture of uppercase and lowercase",
  "reset.password.rule3": "A mixture of letters and numbers",
  "reset.password.rule4.sub1": "Inclusion of at least one special character:",
  "reset.password.rule4.sub2": "! @ # ? ] _ +",
  "reset.password.link.text1": "Back to Login",

  // warning modal
  "warningmodal.title": "Are you sure you want to logout?",
  "warningmodal.fileupload.delete.title":
    "Are you sure you want to delete this file?",
  "warningmodal.childinfo.delete.title":
    "Are you sure you want to delete this child?",
  "warningmodal.confirm": "Yes",
  "warningmodal.cancel": "No",

  // validators
  "validation.password.required": "Password is required",
  "validation.password.min": "Password must be 8+ characters",
  "validation.password.invalid.space": "Space not allowed",
  "validation.email.required": "Email is required",
  "validation.email": "This doesn’t seem to be an email",
  "validation.fax.invalid": "Fax is not valid",
  "validation.phone.invalid": "Phone number is not valid",
  "validation.time": "Invalid Time",
  "validation.name.min": "Minimum 2 characters required",
  "validation.phone.max": "Should be maximum 10 characters long",
  "validation.phone.min": "Should be minimum 10 characters long",
  "validation.mileageentry.invalid.miles": "Invalid Miles",
  "validation.expense.invalid.cost": "Invalid Cost",
  "validation.expense.invalid.stamps": "Invalid No. of Stamps",
  "validation.expense.invalid.copies": "Invalid No. of Copies",
  "validation.claim.additional.invalid.charges": "Invalid No. of Charges",

  // Filters
  "client.type": "Type",
  "claim.bill.entry": "Billing Entry Status",
  "claim.intake.review.status": "Intake Review Status",

  // General Headings
  "card.docket.no": "Docket No.",
  "card.docket.number": "Docket Number",
  "card.county": "County",
  "card.casetype": "Case Type",
  "card.courttype": "Court Type",
  "card.status": "Status",
  "card.dob": "Date of birth",
  "card.notifications": "Notifications",
  "card.openclaims.count": "No. of open claims",
  "card.date.lastbill": "Date of Last Billing Entry",
  "card.claimtype": "Claim Type",
  "card.time.category": "Category",
  "card.time.task": "Task",
  "card.time.duration": "Duration",
  "card.time.date": "Date",
  "card.expense.cost": "Total Expense Cost",
  "card.expense.category": "Expense Category",
  "card.expense.category.type": "Expense Type",
  "card.mileage.origintodest": "Origin and Destination",
  "card.mileage.category": "Mileage Category",
  "card.traveltime.category": "Travel Time Category",
  "card.mileage.miles": "Total Miles",
  "card.entrytype": "Entry type",
  "info.client": "Client Information",
  "info.client.phone": "Phone Number",
  "info.client.email": "Email",
  "info.client.address": "Address",
  "info.contact": "Contact Information",
  "info.case": "Case Information",
  "info.case.representation": "Representation",
  "info.case.appointment": "Appointment order",
  "info.case.appointment.remove": "Remove Appointment Orders",
  "info.case.petition.remove": "Remove Petition Documents",
  "info.case.petition": "Petition Document",
  "info.expense.receipt": "Receipt",
  "info.expense.receipt.remove": "Remove Receipt",
  "info.claim": "Claim Information",
  "info.claim.court": "Court",
  "info.claim.judge": "Judge",
  "info.claim.entry": "Entry",
  "info.account.name": "Name",
  "button.addnew": "Add New",
  "button.add.ooa": "Upload appointment order",
  "button.add.petition": "Upload petition document",
  "button.save.ooa": "Save OOA Documents",

  // My Clients
  "myclients.heading": "Clients",
  "myclients.button.text": "my clients",
  "client.detail.heading.client": "Client",
  "add.client.heading": "Add Client",

  // My Cases
  "mycases.heading": "Cases",
  "mycases.button.text": "my cases",
  "casedetail.client.heading": "Case",
  "add.case.heading": "Add Case",

  // My Claims
  "myclaims.heading": "Claims",
  "myclaims.button.text": "my claims",
  "claimdetail.client.heading": "Claim",
  "add.claim.heading": "Add Claim",

  // MyAccount
  "myaccount.heading": "My Account",
  "myaccount.home.text": "Account",
  "myaccount.signin.heading": "Sign In Information",
  "info.account.home.phone": "Home Phone",
  "info.account.office.phone": "Office Phone",
  "info.account.cell.phone": "Cell Phone",
  "info.account.fax": "Fax",
  "info.account.email.primary": "Primary Email",
  "info.account.email.secondary": "Secondary Email",
  "info.account.address.billing": "Billing Address",
  "info.account.acap.heading": "ACAP Information",
  "info.account.acap.show": "Show ACAP Information",
  "info.account.acap.hide": "Hide ACAP Information",
  "info.account.acap.change.password": "Change ACAP Password",
  "info.account.address.label": "Address Label",
  "info.account.address1": "Address Line 1",
  "info.account.address2": "Address Line 2",
  "info.account.address3": "Address Line 3",
  "info.account.city": "City",
  "info.account.state": "State",
  "info.account.zip": "Zip",
  "info.account.billing.address.label": "Billing Address Label",
  "info.account.billing.address1": "Billing Address Line 1",
  "info.account.billing.address2": "Billing Address Line 2",
  "info.account.billing.address3": "Billing Address Line 3",
  "info.account.billing.city": "Billing Address City",
  "info.account.billing.state": "Billing Address State",
  "info.account.billing.zip": "Billing Address Zip",
  "myaccount.edit.same.billaddress": "Billing Address same as Address",
  "myaccount.acap.security.questions": "Security Questions",
  "acap.security.answer1": "Answer 1",
  "acap.security.answer2": "Answer 2",
  "acap.security.answer3": "Answer 3",
  "acap.modal.heading": "Validate User",
  "acap.question.add": "Add Question",
  "acap.question.delete": "Delete Question",
  "acap.question.validation":
    "No two security questions can be same. Please select other one!!",

  // EC Cap Request
  "ec.cap.request.heading": "EC Cap Request",
  "ec.cap.request.upload.text":
    "To extend your payment cap, upload a judge signed form below. Approved EC Cap Requests will double the amount available for billing.",
  "ec.cap.request.questionaire.text":
    "Let us do the work for you. Fill out the questionaire, and we’ll create the paper work needed for the judges signature.",
  "uploaded.form": "FORM UPLOADED",
  "download.request.form.text":
    "EC Cap Request form has been uploaded. You can preview the uploaded form below",
  "upload.approved.ec.cap.button.text": "UPLOAD APPROVED EC CAP",
  "fill.questionare.button.text": "FILL OUT QUESTIONAIRE",
  "preview.ec.cap.request.button.text": "PREVIEW EC CAP REQUEST FORM",
  "apply.ec.cap.button": "apply for EC cap",
  "attorney.label": "Attorney",
  "bpr.label": "BPR#",
  "acap.id.number.label": "ACAP ID Number",
  "dn.label": "D/N GAL/AAL",
  "oldest.child.name.label": "Oldest Child Name",
  "type.of.trial.label": "Type of Trial",
  "how.many.label": "How Many",
  "please.describe.label": "Please Describe",
  "what.type.of.petition.label": "What type of petition?",
  "client.name.label": "Client Name",
  "children.detail.label": "Children Detail",
  "rush.request": "Rush Request",
  "length.case.in.months.label": "Length of case in months",
  "was.length.case.extended.label": "Was the length of the case extended?",
  "How.many.hearings.label": "How many hearings were there?",
  "were.hearings.lengthy.label": "Were any of the hearings lengthy?",
  "describe.reason.lengthy.hearing.label":
    "Describe the reason for lengthy hearing",
  "court.date.reason.appearance.continuance.label":
    "For Each Court Date, Please list reason for Court Appearance, if simply for a continuance, please state the reason for continuance",
  "how.many.attorneys.label": "How many attorneys involved?",
  "how.many.parties.label": "How many parties?",
  "how.many.defendants.label": "How many Defendants?",
  "how.many.pro.se.defendants.label": "How many Pro Se Defendants?",
  "any.complicating.factors.label": "Any complicating factors",
  "how.many.witnesses.prepared.for.label":
    "How many WITNESSES DID YOU PREPARE FOR?",
  "how.many.witnesses.called.label":
    "How many WITNESSES were called at hearings, not necessarily trial?",
  "any.intervening.petitions.label": "Were there any intervening petitions?",
  "how.many.intervening.petitions.label": "how many intervening Petitions",
  "any.novel.or.complex.issues.of.law.label":
    "Were there any novel or complex issues of law",
  "any.novel.or.complex.fact.issues.label":
    "Were there any novel or complex FACT ISSUES?",
  "was.there.scientific.evidence.label": "Was there Scientific Evidence?",
  "what.was.it.label": "what was it :",
  "was.it.presented.at.hearings.label":
    "was it presented at hearings in this matter :",
  "were.expert.witnesses.involved.label":
    "Were there expert witnesses involved?",
  "please.list.how.many.label": "please list how many",
  "subject.matter.each.expert.on.label":
    "and the subject matter that each was an expert on:",
  "were.depositions.taken.experts.label": "Were depositions taken of experts",
  "whose.were.taken.label": "whose were taken?",
  "how.many.experts.called.trial.label":
    "how many experts were called at trial",
  "explain.subject.matter.each.witness.label":
    "Please explain subject matter for each witness",
  "were.other.depositions.taken.label": "Were other depositions taken?",
  "number.of.charges.label": "Number of charges :",
  "any.factors.that.complicated.charges.label":
    "Were there any factors that complicated the charges?",
  "describe.factors.that.complicated.charges.label":
    "Please describe the factors that complicated the charges:",
  "did.you.visit.crime.scene.label": "Did you visit the crime scene?",
  "how.many.times.label": "how many times?",
  "any.complicating.extending.factor.crime.scene.label":
    "List any complicating or extending factor about the crime scene:",
  "was.casa.involved": "Was CASA Involved",
  "how.many.case.related.meetings.with.label":
    "How many case related meetings with Caseworkers, Teachers, Coaches, Etc.",
  "were.these.meeting.all.required.label": "Were these meeting all required",
  "how.many.cftm.meetings.label": "How many CFTM Meetings?",
  "how.many.family.members.involved.label":
    "How many family members were involved?",
  "describe.complicated.factors.family.members.label":
    "Describe any complicated factors with family members involved",
  "how.many.children.involved.label": "How many children were involved?",
  "list.age.of.each.child.label": "Please list age of each child involved",
  "describe.any.complicating.factors.age.children.label":
    "Describe any complicating factors with age of children",
  "how.many.parties.involved.label": "How many parties were involved?",
  "describe.complicating.party.factors.label":
    "Describe any complicating party factors",
  "were.there.any.disruptions.label": "Were there any disruptions?",
  "check.applicable.pro.se.label":
    "Were any of the following Pro Se, please check if applicable:",
  "pro.se.petitioners.label": "Pro se Petitioners",
  "pro.se.intervening.petitioners.label": "Pro Se Intervening Petitioners",
  "pro.se.respondents.label": "Pro Se Respondents",
  "pro.se.co.defendants.label": "Pro Se Co-Defendants",
  "was.lot.of.travel.involved.label": "Was there a lot of travel involved?",
  "state.the.reason.why.label": "please state the reason why?",
  "check.following.applicable.discovery.label":
    "Please check the following applicable discovery",
  "voluminous.documents.label": "Voluminous Documents",
  "physical.evidence.label": "Physical Evidence",
  "audio.tapes.label": "Audio Tapes",
  "video.tapes.label": "Video Tapes",
  "scientific.data.label": "Scientific Data",
  "medical.reports.diagnosis.etc.label": "Medical reports, diagnosis, etc.",
  "mental.health.reports.diagnosis.etc.label":
    "Mental Health reports, diagnosis, etc.",
  "other.label": "Other",
  "state.complicating.factors.discovery.label":
    "Please state any complicating factors related to discovery",
  "list.other.summary.or.other.facts.that.complicated.label":
    "Please list any other summary or other facts that you believe complicated or extended the matter",
  "view.uploaded.document": "View uploaded Document",

  // Intakes
  "add.entry.heading": "Intake",
  "intake.client.firstname.label": "First Name*",
  "intake.client.middlename.label": "Middle Name",
  "intake.client.lastname.label": "Last Name*",
  "intake.client.dob.label": "Date of Birth*",
  "intake.client.addContact": "Add Contact Information ",
  "intake.client.work.phone": "Work Phone",
  "intake.client.alternate.phone": "Alternate Phone",
  "intake.case.file.upload.fail": "Uploaded Files must be a PDF document",
  "intake.claim.entry.heading": "Entry",
  "intake.claim.add.time": "Add Time Entry",
  "intake.claim.add.expense": "Add Expense Entry",
  "intake.claim.add.mileage": "Add Mileage Entry",
  "intake.claim.add.timetravel": "Add Time Travel Entry",

  // Additional Claim Intake
  "claim.intake.additional.info": "Additional Information",
  "claim.intake.additional.interim.heading":
    "Is this an interim bill or the first bill for this capital case?",
  "claim.intake.lastbill": "Last Billing Date",
  "claim.intake.additional.child": "Child",
  "claim.intake.additional.chargedoffense": "Charged Offenses",
  "claim.intake.additional.underlyingoffense": "Underlying Offenses",
  "claim.child.modal.heading": "Add Child",
  "claim.chargedoffense.modal.heading": "Add Charged Offense",
  "claim.underlyingoffense.modal.heading": "Add Underlying Offense",
  "claim.child.modal.name": "Child Name",
  "claim.child.modal.dob": "Date of Birth",
  "claim.detail.child.heading": "Added Child Information",
  "claim.detail.offense.heading": "Added Offense Information",
  "claim.offense.title": "Offense Title",
  "claim.offense.codesection": "Offense Code Section",
  "claim.offense.class": "Class of Offense",
  "claim.offense.noofcharges": "No. of Charges This Offense",
  "claim.peition.heading": "New D&N Allegations",
  "claim.clientrealtion.heading": "My client is",
  "claim.ooa.note":
    "Kindly Upload OOA Case documents under Case Section, If not already uploaded..!!",

  // Entry Intake
  "time.entry.intake.heading": "New Time Entry",
  "time.entry.button.text": "Add Time Entry",
  "time.entry.add.client": "Add New Client",
  "time.entry.add.case": "Add New Case",
  "time.entry.add.claim": "Add New Claim",
  "time.entry.heading.sub": "Time",
  "time.entry.label.category": "Category",
  "time.entry.label.task": "Task",
  "time.entry.label.duration": "Duration",
  "time.entry.task.add": "Add New Task",
  "time.entry.button.add": "Add New Entry",
  "time.entry.label.hour": "Hour",
  "time.entry.label.minute": "Minute",
  "time.entry.label.billentry": "Billing Entry Note",
  "time.entry.label.description": "Task Description",
  "time.entry.addAsStandardTask": "Add as Standard Task",
  "time.entry.toggletoTaskList": "Back to Task List",

  "expense.entry.intake.heading": "New Expense Entry",
  "expense.entry.button.text": "Add Expense Entry",
  "expense.entry.heading.sub": "Expense",
  "expense.entry.parkingcost.label": "Parking Cost",
  "expense.entry.postagecost.label": "Postage Cost",
  "expense.entry.typeofcopies.label": "Type of Copies",
  "expense.entry.noofcopies.label": "Number of Copies",
  "expense.entry.total.cost.label": "Total Copies Cost",
  "expense.entry.outsourced.cost.label": "Per Copy Cost",
  "expense.entry.copy.cost.label": "Copies Cost",
  "expense.entry.outsourced.description.label": "Outsourced Copies Description",
  "expense.entry.noofstamps.label": "Number of Stamps",
  "expense.entry.stamps": "Stamps Cost",
  "expense.entry.misccost.label": "Misc. Cost",
  "expense.entry.misc.description.label": "Misc. Expense Description",
  "expense.entry.typeoflodging.label": "Type of Lodging and Meals",
  "expense.entry.lodging.cost.label": "Lodging and Meals Cost",
  "expense.entry.lodging.description.label": "Lodging and Meals Description",
  "expense.entry.computer.cost": "Computer Research Cost",
  "expense.button.add.expense": "Upload Receipt",

  "mileage.entry.heading.sub": "Mileage",
  "mileage.entry.button.text": "Add Mileage Entry",
  "mileage.entry.intake.heading": "New Mileage Entry",
  "mileage.entry.state": "State",
  "mileage.entry.originDestination": "Origin and Destination",
  "mileage.entry.origin": "Origin",
  "mileage.entry.destination": "Destination",
  "mileage.entry.miles": "Miles",
  "mileage.entry.add.trip": "Add Return Trip",
  "mileage.entry.time": "Travel Time",
  "mileage.entry.add.time": "Add Travel Time",
  "button.add.order": "UPLOAD TRAVEL ORDER",
  "address.modal.heading": "Add New Address",
  "address.modal.addressLabel": "Address Label",
  "info.case.travel": "Travel order",
  "mileage.entry.task.add": "Add New Address",
  "info.mileage.receipt.remove": "Remove Document",

  "traveltime.entry.button.text": "Add Travel Time Entry",
  "traveltime.entry.add.time": "Add Mileage",
  "traveltime.entry.heading.sub": "Travel Time",
  "mileage.entry.mileage": "Mileage",
  "traveltime.entry.intake.heading": "New Travel Time Entry",

  // Dashboard
  "dashboard.main.heading.alert": "Alerts",
  "dashboard.main.button.seeall": "See All",
  "dashboard.main.heading.bill": "Enter Billing",
  "dashboard.main.heading.access": "Access",

  // Notifications
  "notifications.heading.header": "Notifications",
  "notifications.heading.alert": "Alerts",
  "notifications.heading.missing info": "Submission Status",
  "notifications.button.view.client": "View Client",
  "notifications.button.view.case": "View Case",
  "notifications.button.view.claim": "View Claim",
  "notifications.button.view.entry": "View Entry",
  "notifications.button.mark.read": "Mark as read",
  "notifications.button.finish.submission": "FINISH SUBMISSION",

  //Submission
  "submission.heading": "IBS Submission",
  "submission.button.text": "Begin submission",
  "submission.button.submit": "Submit Claim to IBS",
  "submission.missinginfo.text": "Complete the missing info to submit:",
  "required.before.submission.text": "Required before submission:",
  "optional.before.submission.text":
    "Highly recommended but not required for submission:",
  "submission.claim.ready": "Active",
  "submission.claim.not.ready": "Pending",
  "submission.claim.submitted.ibs": "Claim Submitted to IBS",
  "submission.claim.interim.text":
    "Is this an interim claim or the final claim for this capital case?",
  "submission.claim.appeal.text": "Will you be appealing this case?",
  "submission.claim.appeal2.text":
    "Will this case have another phase or is this the last claim for this case?",
  "submission.claim.disposition.text": "Case Disposition",
  "submission.claim.conviction.text": "Conviction Type",
  "submission.final.text1": "Are you sure you want to submit to IBS? ",
  "submission.final.text2":
    "You will no longer be able to enter billing on this claim after submitting.",
  "submission.claim.judge.heard.text":
    "Judge that Heard Different from Appointed",
  "submission.claim.judge": "Judge that Heard",
  "submission.claim.different.judge": "If Not Listed, Enter Judge",
  "submission.claim.conviction.add": "Add Conviction Offense",
  "submission.claim.conviction.modal.heading": "Conviction Offenses",
  "submission.sentence.received": "Sentence Received",
  "submission.disposition.date": "Disposition Date",
  "submission.dependencyneglect.text": "Was Dependency and Neglect Found?",
  "submissiom.phase.judge.text": "New Phase Judge Different from Last Claim",
  "submission.phase.docket.text":
    "New Phase Docket Number Different from Last Claim",
  "submission.phase.court.text":
    "New Phase Court and Judge Different from Last Claim",
  "submission.phase.judge.card.text": "New Phase Judge",
  "submission.phase.docket.card.text": "New Phase Docket Number",
  "submission.phase.court.card.text": "New Phase Court",
  "submission.phase.heading": "New Phase",
  "submission.phase.claims.heading": "Next D&N Phase",
  "submission.capital.text": "Is this the final bill for the trial?",
  "ec.cap.request.pending.text":
    "Your claim cannot be submitted as your EC cap request for this claim is pending",
  "ec.rejected.submission.confirm":
    "Your EC cap request has been rejected. Do you still want to submit this claim?",
  continue: "Continue",
};

export type LocaleString = keyof typeof en;
