import {
  AuthenticationException,
  PermissionException,
  ServerException,
} from "exceptions";
import axios, { AxiosRequestConfig } from "axios";
import { getValue } from "settings/config";
import { saveCookie, removeCookie } from "utils/cookie";

import env from "../env";
import { logError, deviceInformation } from "../utility";
import { ApiInit, ApiResult } from "./api.types";
import getResponseContent from "./getResponseContent";
import { ACCESS_TOKEN, AUTH_TOKEN_COOKIE } from "./getTokens";
import makeRequestInit, { makeRequestInitt } from "./makeRequestInit";

export interface ApiOptions extends ApiInit {
  keepBaseUri?: boolean;
  sendAccessToken?: boolean;
}

export default async function apiFetch<DataType = any>(
  relativeUri: string,
  options: ApiOptions = {}
) {
  try {
    const init = makeRequestInit(options);
    const { keepBaseUri } = options;
    const base = keepBaseUri
      ? process.env.REACT_APP_API_HOST
      : `${process.env.REACT_APP_API_HOST}`;
    const response = await fetch(`${base}${relativeUri}`, init);

    if (response.status >= 400) {
      throw response;
    }

    const result: ApiResult<DataType> = {
      response,
      data: await getResponseContent<DataType>(response),
    };

    return result;
  } catch (response) {
    const content = await getResponseContent(response);
    let message;

    if (content && content.message) {
      ({ message } = content);
    }

    if (response.status === 401) {
      throw new AuthenticationException(message);
    }

    if (response.status === 403) {
      throw new PermissionException(message);
    }

    throw new ServerException(content);
  }
}

const axiosInstance = axios.create({
  baseURL: env.API_HOST,
  timeout: 60000, // cancel api request after 10 secs
});
export const api = async <DataType = any>(
  relativeUri: string,
  options: AxiosRequestConfig = {}
) => {
  const init = await makeRequestInitt(options);
  try {
    const response: DataType = await axiosInstance.request({
      ...init,
      url: relativeUri,
    });
    return response;
  } catch (error) {
    logError(error);
    const errorCode = (
      getValue(["response", "data", "error_code"], error) || ""
    ).toUpperCase();

    // for error messages, please refer errorcode.ts file
    if (errorCode === "ACCESS_TOKEN.VERIFY.FAILED.EXPIRED") {
      const {
        data: { data },
      } = await api("/access-token/refresh", {
        method: "patch",
      });

      // save new access token from response
      saveCookie(ACCESS_TOKEN, data.access_token);

      // retry failed request
      api(relativeUri, options);
    } else if (errorCode.match(/^(access_token\.([A-Za-z]*)\.failed)/gi)) {
      const {
        data: { data },
      } = await axios.get("/access-token", {
        params: {
          ...deviceInformation(),
          unique_identifier: Math.floor(Math.random() * 1e10),
        },
      });

      // save new access token from response
      saveCookie(ACCESS_TOKEN, data.access_token);

      // retry failed request
      api(relativeUri, options);
    } else if (errorCode.match(/^(auth_token\.([A-Za-z]*)\.failed)/gi)) {
      removeCookie(AUTH_TOKEN_COOKIE);
      localStorage.removeItem("userData");
      window.location.reload();
    }

    if (error.response) {
      throw new ServerException({
        message:
          getValue(["response", "data", "errors"], error).length > 0
            ? getValue(["response", "data", "errors", "0", "message"], error)
            : getValue(["response", "data", "message"], error),
      });
    }

    if (error.response && error.response.status === 401) {
      throw new AuthenticationException(error.message);
    }

    throw new ServerException({ message: error.message });
  }
};
