import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router";

import { Loader } from "atoms";
import { composeComponents } from "utils/component";
import { ClientsProvider } from "pages/MyClients/MyClientsContext";
import { CasesProvider } from "pages/MyCases/MyCasesContext";

import { AccountProvider } from "pages/MyAccount/MyAccountContext";
import { AddIntakeProvider } from "pages/AddIntake/AddIntakeContext";
import { AddECCapSubmissionProvider } from "pages/ECCapRequest/ECCapContext";
import { ClaimsProvider } from "pages/MyClaims/MyClaimsContext";
import { AddEntryIntakeProvider } from "pages/EntryIntake/AddEntryIntakeContext";
import { NotificationsProvider } from "pages/Notifications/NotificationsContext";
import { SubmissionProvider } from "pages/IBSSubmission/SubmissionContext";

// Authentication
const HomePage = lazy(
  () => import(/* webpackChunkName: 'HomePage' */ "pages/HomePage")
);
const Login = lazy(() => import(/* webpackChunkName: 'Login' */ "pages/Login"));
const Logout = lazy(
  () => import(/* webpackChunkName: 'Logout' */ "pages/Logout")
);
const ChangePassword = lazy(
  () => import(/* webpackChunkName: 'ChangePassword' */ "pages/ChangePassword")
);
const ForgotPassword = lazy(
  () => import(/* webpackChunkName: 'ForgotPassword' */ "pages/ForgotPassword")
);
const ResetPassword = lazy(
  () => import(/* webpackChunkName: 'ResetPassword' */ "pages/ResetPassword")
);

// Clients
const MyClients = lazy(
  () => import(/* webpackChunkName: 'MyClients' */ "pages/MyClients")
);
const ClientDetail = lazy(
  () =>
    import(
      /* webpackChunkName: 'ClientDetail' */ "pages/MyClients/ClientDetail"
    )
);

// Cases
const MyCases = lazy(
  () => import(/* webpackChunkName: 'MyCases' */ "pages/MyCases")
);
const CaseDetail = lazy(
  () => import(/* webpackChunkName: 'CaseDetail' */ "pages/MyCases/CaseDetail")
);

// Claims
const MyClaims = lazy(
  () => import(/* webpackChunkName: 'MyClaims' */ "pages/MyClaims")
);
const ClaimDetail = lazy(
  () =>
    import(/* webpackChunkName: 'ClaimDetail' */ "pages/MyClaims/ClaimDetail")
);

// Intakes
const AddIntake = lazy(
  () => import(/* webpackChunkName: 'AddIntake' */ "pages/AddIntake")
);
const AddIntakeClientView = lazy(
  () =>
    import(
      /* webpackChunkName: 'AddIntakeClientView' */ "pages/AddIntake/AddClientIntake/IntakeClientDetail"
    )
);
const AddIntakeCaseView = lazy(
  () =>
    import(
      /* webpackChunkName: 'AddIntakeCaseView' */ "pages/AddIntake/AddCaseIntake/IntakeCaseDetail"
    )
);
const AddIntakeClaimView = lazy(
  () =>
    import(
      /* webpackChunkName: 'AddIntakeClaimView' */ "pages/AddIntake/AddClaimIntake/IntakeClaimDetail"
    )
);

// MyAccount
const MyAccount = lazy(
  () => import(/* webpackChunkName: 'MyAccount' */ "pages/MyAccount")
);

// Time Entry Intake
const AddTimeEntryIntake = lazy(
  () =>
    import(
      /* webpackChunkName: 'AddTimeEntryIntake' */ "pages/EntryIntake/AddTimeEntryIntake"
    )
);
const TimeEntryDetail = lazy(
  () =>
    import(
      /* webpackChunkName: 'TimeEntryIntake' */ "pages/EntryIntake/AddTimeEntryIntake/TimeEntryDetail"
    )
);

// Expense Entry Intake
const AddExpenseEntryIntake = lazy(
  () =>
    import(
      /* webpackChunkName: 'AddExpenseEntryIntake' */ "pages/EntryIntake/AddExpenseEntryIntake"
    )
);
const ExpenseEntryDetail = lazy(
  () =>
    import(
      /* webpackChunkName: 'TimeEntryIntake' */ "pages/EntryIntake/AddExpenseEntryIntake/ExpenseEntryDetail"
    )
);

// Mileage Entry Intake
const AddMileageEntryIntake = lazy(
  () =>
    import(
      /* webpackChunkName: 'AddMileageEntryIntake' */ "pages/EntryIntake/AddMileageEntryIntake"
    )
);

const MileageEntryDetail = lazy(
  () =>
    import(
      /* webpackChunkName: 'TimeEntryIntake' */ "./pages/EntryIntake/AddMileageEntryIntake/MileageEntryDetail"
    )
);

// Travel Time Entry Intake
const AddTravelTimeEntryIntake = lazy(
  () =>
    import(
      /* webpackChunkName: 'AddTravelTimeEntryIntake' */ "pages/EntryIntake/AddTravelTimeEntryIntake"
    )
);

const TravelTimeEntryDetail = lazy(
  () =>
    import(
      /* webpackChunkName: 'TravelTimeEntryDetail' */ "pages/EntryIntake/AddTravelTimeEntryIntake/TravelTimeEntryDetail"
    )
);

//IBS Submission
const IBSSubmission = lazy(() => import("pages/IBSSubmission"));

// Notifications
const Notifications = lazy(
  () => import(/* webpackChunkName: 'Notifications' */ "pages/Notifications")
);

const ECCapRequest = lazy(
  () => import(/* webpackChunkName: 'Notifications' */ "pages/ECCapRequest")
);

export const UnauthenticatedRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Redirect to="/login" />
      </Switch>
    </Suspense>
  );
};

const ClaimProvider = () => {
  return (
    <ClaimsProvider>
      <CasesProvider>
        <ClientsProvider>
          <Switch>
            <Route
              path="/add-intake/claim/:id"
              component={AddIntakeClaimView}
            />
            <Route path="/add-intake/case/:id" component={AddIntakeCaseView} />
            <Route
              path="/add-intake/client/:id"
              component={AddIntakeClientView}
            />
            <Route path="/add-intake" component={AddIntake} />
            <Route path="/my-clients/:id" component={ClientDetail} />
            <Route path="/my-clients" component={MyClients} />
            <Route path="/my-cases/:id" component={CaseDetail} />
            <Route path="/my-cases" component={MyCases} />
            <Route path="/my-claims/:id" component={ClaimDetail} />
            <Route path="/my-claims" exact component={MyClaims} />
            <Route path="/ibs-submission/:id" component={IBSSubmission} />
          </Switch>
        </ClientsProvider>
      </CasesProvider>
    </ClaimsProvider>
  );
};

export const AuthenticatedRoutes = () => {
  const Providers = composeComponents(
    AccountProvider,
    AddIntakeProvider,
    AddEntryIntakeProvider,
    NotificationsProvider,
    SubmissionProvider,
    AddECCapSubmissionProvider
  );
  return (
    <Providers>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path="/home" component={HomePage} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/ibs-submission/:id" component={ClaimProvider} />
          <Route path="/my-claims" component={ClaimProvider} />
          <Route path="/my-cases" component={ClaimProvider} />
          <Route path="/my-clients" component={ClaimProvider} />
          <Route path="/add-intake/client/:id" component={ClaimProvider} />
          <Route path="/add-intake/case/:id" component={ClaimProvider} />
          <Route path="/add-intake" component={ClaimProvider} />
          <Route path="/add-time-entry/:id" component={TimeEntryDetail} />
          <Route path="/add-time-entry" component={AddTimeEntryIntake} />
          <Route path="/add-expense-entry/:id" component={ExpenseEntryDetail} />
          <Route path="/add-expense-entry" component={AddExpenseEntryIntake} />
          <Route path="/add-mileage-entry/:id" component={MileageEntryDetail} />
          <Route path="/add-mileage-entry" component={AddMileageEntryIntake} />
          <Route
            path="/add-traveltime-entry/:id"
            component={TravelTimeEntryDetail}
          />
          <Route
            path="/add-traveltime-entry"
            component={AddTravelTimeEntryIntake}
          />

          <Route path="/my-account" component={MyAccount} />
          <Route path="/notifications" component={Notifications} />
          <Route path="/ec-cap-request/:id" component={ECCapRequest} />
          <Route path="/logout" component={Logout} />
          <Redirect to="/home" />
        </Switch>
      </Suspense>
    </Providers>
  );
};
