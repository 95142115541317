import { useCallback, useReducer } from "react";
import { toast } from "react-toastify";
import { api } from "utils/api/api";
import {
  createContainer,
  createReducer,
  createAsyncActions,
} from "utils/context";

export type ECCapContextType = {
  uploadDetails?: object | any;
  ecLoading: boolean;
  ECMetaDataLoading: boolean;
  ECSavedDataLoading: boolean;
  ECMetaData: object | any;
  ECSavedData: object | any;
};

const initialState: ECCapContextType = {
  ecLoading: true,
  ECMetaDataLoading: true,
  ECMetaData: null,
  ECSavedDataLoading: true,
  ECSavedData: null,
};

const actions = {
  setNewECCapUpload: createAsyncActions("SET_NEW_EC_CAP_UPLOAD"),
  setECMetaData: createAsyncActions("SET_EC_META_DATA"),
  setNewECDetail: createAsyncActions("SET_NEW_EC_DETAIL"),
  updateECDetail: createAsyncActions("UPDATE_EC_DETAIL"),
  setECSavedData: createAsyncActions("SET_SAVED_DATA"),
};

const ecCapUploadReducer = createReducer<ECCapContextType>({
  [`${actions.setNewECCapUpload.success}`]: (state, { payload }) => ({
    ...state,
    uploadDetails: payload,
  }),
  [`${actions.setECMetaData.loading}`]: (state) => ({
    ...state,
    ECMetaDataLoading: true,
  }),
  [`${actions.setECMetaData.success}`]: (state, { payload }) => ({
    ...state,
    ECMetaData: payload,
    ECMetaDataLoading: false,
  }),
  [`${actions.setECMetaData.failure}`]: (state) => ({
    ...state,
    ECMetaDataLoading: false,
  }),
  [`${actions.setNewECDetail.success}`]: (state, { payload }) => ({
    ...state,
    ecDetail: payload,
  }),
  [`${actions.updateECDetail.success}`]: (state, { payload }) => ({
    ...state,
    ecDetail: payload,
  }),
  [`${actions.setECSavedData.loading}`]: (state) => ({
    ...state,
    ECSavedDataLoading: true,
  }),
  [`${actions.setECSavedData.success}`]: (state, { payload }) => ({
    ...state,
    ECSavedData: payload,
    ECSavedDataLoading: false,
  }),
  [`${actions.setECSavedData.failure}`]: (state, { payload }) => ({
    ...state,
    ECSavedData: payload,
    ECSavedDataLoading: false,
  }),
});

export const {
  useContext: useECCapSubmission,
  Provider: AddECCapSubmissionProvider,
} = createContainer(() => {
  const [state, dispatch] = useReducer(ecCapUploadReducer, initialState);

  const setNewECCapUpload = useCallback(
    async (formData, onSuccess: () => void) => {
      dispatch(actions.setNewECCapUpload.loading());
      try {
        const { data } = await api(`/ec-questionnaire/upload`, {
          method: "post",
          data: formData,
        });
        dispatch(actions.setNewECCapUpload.success(data?.data));
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
        dispatch(actions.setNewECCapUpload.failure());
      }
    },
    []
  );

  const getECMetadata = useCallback(async (claimId: number) => {
    dispatch(actions.setECMetaData.loading());
    try {
      const { data } = await api(`/ec-questionnaire/metadata/${claimId}`, {
        method: "get",
      });
      dispatch(actions.setECMetaData.success(data.data));
    } catch (e) {
      dispatch(actions.setECMetaData.failure());
    }
  }, []);

  const getSavedECQuestionnaire = useCallback(async (claimId: number) => {
    dispatch(actions.setECSavedData.loading());
    try {
      const { data } = await api(`/ec-questionnaire/${claimId}`, {
        method: "get",
      });
      dispatch(actions.setECSavedData.success(data.data));
    } catch (e) {
      dispatch(actions.setECSavedData.failure());
    }
  }, []);

  const setNewECDetail = useCallback(
    async (formData, onSuccess: () => void) => {
      dispatch(actions.setNewECDetail.loading());
      try {
        const { data } = await api(`/ec-questionnaire`, {
          method: "post",
          data: formData,
        });
        dispatch(actions.setNewECDetail.success(data?.data));
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
        dispatch(actions.setNewECDetail.failure());
      }
    },
    []
  );

  const updateECDetail = useCallback(
    async (ec_questionnaire_id: number, formData, onSuccess: () => void) => {
      dispatch(actions.updateECDetail.loading());
      try {
        const { data } = await api(`/ec-questionnaire/${ec_questionnaire_id}`, {
          method: "POST",
          data: { _method: "PATCH", ...formData },
        });
        dispatch(actions.updateECDetail.success(data?.data));
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
        dispatch(actions.updateECDetail.failure());
      }
    },
    []
  );

  return {
    state,
    actions: {
      setNewECCapUpload,
      getECMetadata,
      setNewECDetail,
      updateECDetail,
      getSavedECQuestionnaire,
    },
  };
});
