import React, { forwardRef, ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { BoxProps, Box } from "atoms/Box";
import { LocaleString } from "locales";

export interface TextProps extends BoxProps {
  children?: any;
  textDecoration?: string;
  onClick?: any;
  htmlFor?: string;
  firstLetterCapital?: boolean;
  whiteSpace?:
    | "normal"
    | "nowrap"
    | "pre"
    | "pre-line"
    | "pre-wrap"
    | "initial"
    | "inherit";
  wordBreak?:
    | "normal"
    | "break-all"
    | "keep-all"
    | "break-word"
    | "initial"
    | "inherit";
  /**
   * Determines if the text should be lozalized.
   */
  locale?: boolean;
  /**
   *
   */
  values?: Record<string, any>;
  /**
   * id for the localized string
   */
  id?: LocaleString;
  /**
   * For testing
   */
  "data-testid"?: string;
  ref?: any;
}

export const Text = forwardRef<ReactElement | HTMLElement, TextProps>(
  ({ id, children, values, ...props }, ref) => {
    if (id) {
      return (
        <FormattedMessage id={id} values={values}>
          {(text) => (
            <Box ref={ref} {...props}>
              {text}
            </Box>
          )}
        </FormattedMessage>
      );
    }
    return (
      <Box ref={ref} {...props}>
        {children}
      </Box>
    );
  }
);
Text.defaultProps = {
  children: "",
};
