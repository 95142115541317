import { Loader } from "atoms";
import React, { FC } from "react";
import { UnauthenticatedRoutes, AuthenticatedRoutes } from "Routes";
import useAuth from "./contexts/Authentication";

export type AuthGateProps = {};

export const AuthGate: FC<AuthGateProps> = () => {
  const {
    state: { isLoggedIn, authenticating },
  } = useAuth();

  if (authenticating) {
    return <Loader />;
  }

  if (isLoggedIn) {
    return <AuthenticatedRoutes />;
  }

  return <UnauthenticatedRoutes />;
};
