import React, { useState, FC, useCallback } from "react";
import LazyLoad from "react-lazy-load";
import { Flex } from "atoms/Flex";
import imagePlaceholder from "assets/images/imagePlaceholder.png";

import { BaseWrapperProps, ImageWrapper } from "./Image.styles";

interface BaseImageWrapperProps extends BaseWrapperProps {
  src?: any;
  alt?: string | null;
  /**
   * If true, will enable lazy loading of the image,(will load image when scrolled over)
   */
  lazy?: boolean;
}

type LazyImageProps = {} & Omit<BaseImageWrapperProps, "lazy">;

const LazyImage: FC<LazyImageProps> = ({ src, alt }) => {
  const [loaded, setLoaded] = useState(false);
  const [fetched, setFetched] = useState(false);

  const contentLoaded = useCallback(() => {
    setLoaded(true);
  }, []);

  const contentFetched = useCallback(() => {
    setFetched(true);
  }, []);

  return (
    <>
      <LazyLoad
        debounce={false}
        offset={500}
        height="100%"
        onContentVisible={contentLoaded}
      >
        <Flex opacity={Number(fetched)} height="100%">
          <img
            src={src || imagePlaceholder}
            alt={alt as string | undefined}
            width="100%"
            height="100%"
            onLoad={contentFetched}
          />
        </Flex>
      </LazyLoad>
      {(!loaded || !fetched) && (
        <Flex
          height="100%"
          opacity={Number(!loaded || !fetched)}
          position="absolute"
          top={0}
          left={0}
          right={0}
          maxWidth={{ xs: "20rem", md: "35rem" }}
          margin="auto"
        ></Flex>
      )}
    </>
  );
};

export const Image: FC<BaseImageWrapperProps> = React.memo(
  ({ lazy, src, alt, ...rest }) => {
    return (
      <ImageWrapper {...rest}>
        {lazy ? (
          <LazyImage src={src || imagePlaceholder} alt={alt} />
        ) : (
          <img
            src={src || imagePlaceholder}
            alt={alt as string | undefined}
            width="100%"
            height="100%"
          />
        )}
      </ImageWrapper>
    );
  }
);
