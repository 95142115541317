import axios, { AxiosRequestConfig } from "axios";

import { saveCookie } from "utils/cookie";
import { deviceInformation } from "utils/utility";

import { ApiInit } from "./api.types";
import makeHeaders from "./makeHeaders";
import { getBearerToken, getAccessToken, ACCESS_TOKEN } from "./getTokens";

const generateDefaults = (): ApiInit => {
  const headers = {};
  const accessToken = getAccessToken();
  const authToken = getBearerToken();
  if (accessToken) {
    headers["access-token"] = accessToken;
  }
  if (accessToken) {
    headers["auth-token"] = authToken;
  }

  return {
    method: "GET",
    headers,
  };
};
const generateApiDefaults = async (): Promise<AxiosRequestConfig> => {
  const headers = {};
  const accessToken = getAccessToken();
  const authToken = getBearerToken();
  if (accessToken) {
    headers["access-token"] = accessToken;
  } else {
    const {
      data: { data },
    } = await axios.get("/access-token", {
      params: {
        ...deviceInformation(),
        unique_identifier: Math.floor(Math.random() * 1e10),
      },
    });
    headers["access-token"] = data.access_token;
    saveCookie(ACCESS_TOKEN, data.access_token);
  }

  if (authToken) {
    headers["auth-token"] = authToken;
  }

  return {
    method: "get",
    headers: {
      ...headers,
    },
  };
};
const noTransformList = [
  "Blob",
  "ArrayBuffer",
  "FormData",
  "URLSearchParams",
  "ReadableStream",
];

function shouldStringify(body: any) {
  if (typeof body === "string") {
    return false;
  }

  if (
    body.constructor &&
    body.constructor.name &&
    noTransformList.some((proto) => body.constructor.name === proto)
  ) {
    return false;
  }

  return true;
}

export default function makeRequestInit(overrides?: ApiInit): RequestInit {
  const init = {
    ...generateDefaults(),
    ...overrides,
  };
  // Convert object to Headers object
  if (init.headers && typeof init.headers === "object") {
    init.headers = makeHeaders(init.headers as any);
  } else {
    init.headers = undefined;
  }
  // Automatically stringify body
  if (init.body && shouldStringify(init.body)) {
    init.body = JSON.stringify(init.body);
  }
  return init;
}

export const makeRequestInitt = async (
  overrides: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const init = {
    ...(await generateApiDefaults()),
    ...overrides,
  };
  return init;
};
